/*
  Creates variables for use within the app project. Any changes made here
  should also be made in mui-theme.ts (if applicable).
 */

@use "sass:color";

$header-height: 64px;

$background-color: #FAFAFA;
$primary-color: #7092BF;
$secondary-color: #70BABF;

:root {
    --app-color_primary: #{$primary-color};
    --app-color_primary-light: #A1C2F2;
    --app-color_primary-dark: #40658F;
    --app-color_primary_very-dark: #{color.adjust(#40658F, $blackness: 20%)};

    --app-color_secondary: #{$secondary-color};

    --app-color_error: #D32F2F;
    --app-color_warn: #ED6C02;
    --app-color_success: #2E7D32;
    --app-color_action: #757575;

    --app-color_background: #{$background-color};
    --app-color_background-medium: #{color.adjust($background-color, $blackness: 5%)};
    --app-color_background-highlighted: #{color.adjust($background-color, $blackness: 4%)};

    --app-color_border: rgba(0, 0, 0, 0.12);;

    --app-color_text: rgba(0, 0, 0, 0.87);
    --app-color_text-secondary: rgba(0, 0, 0, 0.54);

    --app-border-radius: 4px;
    --app-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}
