@use '@tsp-ui/core/sass/utils';

.loanDetailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);

  .fullWidth {
    grid-column: 1/-1;
  }

  .interestSwitch {
    margin-top: utils.spacing(-2);
  }
}

.sectionStart {
  margin-top: utils.spacing(2);
}

.amortizationDetailsFields {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
