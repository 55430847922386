@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(2);
  gap: utils.spacing(1);
  overflow: hidden;
}

.noUploadsTransition_appear {
  transition: opacity 250ms ease 250ms;
  opacity: 0;

  &_active {
    opacity: 1;
  }
}

.fileCard {
  position: relative;
  overflow: hidden;
  padding: utils.spacing(.5, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: utils.spacing(2);
  white-space: nowrap;

  .fileCardPrimaryText {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > :not(.completedOverlay) {
    z-index: 1;
  }

  .completedOverlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
  }

  &.done {
    transition: border 250ms ease;
    border-color: var(--app-color_success);

    .completedOverlay {
      background-color: rgb(237, 247, 237);
    }
  }

  &.error {
    transition: border 250ms ease;
    border-color: var(--app-color_error);

    .completedOverlay {
      background-color: rgb(253, 237, 237);
    }
  }

  &.done, &.error {
    .completedOverlay {
      transition: transform 250ms ease;
      transform: none;
    }
  }
}

.fileIconContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}

.statusPending {
  margin: utils.spacing(.25);
}

.viewUploadButton {
  margin: -5px -5px -5px 0;
}

.fileCardTransition {
  &_enter {
    opacity: 0;
    transform: translateY(-100%);

    &_active {
      opacity: 1;
      transform: none;
      transition: opacity 150ms ease,
        transform 250ms ease;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{50 * $i}ms;
      }
    }
  }

  &_exit {
    opacity: 1;
    transition: opacity 250ms ease;

    &_active {
      opacity: 0;
    }
  }
}
