@use '@tsp-ui/core/sass/utils';

.tableRow td {
  padding-top: utils.spacing(.5) !important;
  padding-bottom: utils.spacing(.5) !important;
}

.tableRowIsEditing td {
  vertical-align: bottom;
}

.feeTypeDropdown {
  padding: 0;
  padding-top: utils.spacing(.5);
  padding-left: utils.spacing(1);
  padding-right: utils.spacing(1);
  min-width: 200px;
}

.amountField input {
  font-size: 14px;
  text-align: right;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}
