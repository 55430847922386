@use '@tsp-ui/core/sass/utils';

.smallField {
    background-color: white;
    margin: 0;
    padding: 0;
    height: 100%;
    padding-left: utils.spacing(1);
}

.fullHeight {
    height: 100%;
}

.fillCell {
    width: 100%;
    height: 100%;
    display: flex;
    & > div {
        height: 100%;

        & > div:nth-child(1)  {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}