@use '@tsp-ui/core/sass/utils';

.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    margin-top: utils.spacing(2);
}

.spanTwo {
    grid-column: span 2;
}

.furtherCredit {
    margin-bottom: utils.spacing(-1.25);
}