@use '@tsp-ui/core/sass/utils';

.container {
  width: 600px;
}

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
  margin-top: utils.spacing(2);
  margin-bottom: utils.spacing(2);

  .fullWidth {
    grid-column: 1/-1;
  }
}
