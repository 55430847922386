@use '@tsp-ui/core/sass/utils';

.timelineRoot::before {
  content: unset;
}

.progressTimeline {
  position: relative;
  padding: 0;
  margin-block: utils.spacing(-1, 1);
  margin-bottom: 0;
}

.timelineButton {
  display: block;
  margin-left: utils.spacing(-1);
}

.timelineEnterTransitionContainer {
  overflow: hidden;
}

.timelineEnterTransition {
  animation: timeline-enter 300ms ease;
}

@keyframes timeline-enter {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.timelineContent {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;

  &.loading {
    animation: loading-breathe 2s ease-out infinite normal;
  }

  &.done {
    animation: loading-breathe 500ms ease 2;
  }
}

@keyframes loading-breathe {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.hiddenConnector {
  visibility: hidden;
}

.popover {
  padding: utils.spacing(2);
  min-width: 175px;
}