@use '@tsp-ui/core/sass/utils';

.productRow {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
  padding: utils.spacing(0.75, 0);
  height: 46px; // Height of row with an icon button in it

  &.compactRow {
    margin-left: 64px;
    height: auto;
  }
}
