@use '@tsp-ui/core/sass/utils';

.branchDetails {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    white-space: nowrap;
    gap: utils.spacing(4);
    padding: utils.spacing(1);
}

.button {
    align-self: center;
}
