@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  align-items: start;
  gap: utils.spacing(6);
  grid-template-columns: 600px 1fr;
  justify-content: space-between;
}

.left {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.paper {
  padding: utils.spacing(1);
  margin-inline: utils.spacing(2);
  overflow: hidden;

  &.loanPaper {
    padding-block: utils.spacing(0.5);
    margin-inline: utils.spacing(2);
    display: flex;
    gap: utils.spacing(2);
    align-items: center;
    padding-left: utils.spacing(2);
    position: relative;
  }
}

.statusBreakdownContainer {
  margin: utils.spacing(-1, 4, 1, 4);
  overflow: hidden;
  padding: utils.spacing(0, 1, 1, 1);
  position: relative;
  border-radius: 4px;
}

.mainLinearProgress {
  border-radius: var(--app-border-radius);
  margin-top: utils.spacing(-0.5) !important; // TODO post-demo
  height: utils.spacing(2) !important;
}

.absoluteLinearProgress {
  position: absolute;
  bottom: 0;
  height: utils.spacing(2);

  &:last-child {
    border-top-right-radius: var(--app-border-radius);
  }
}

.viewLoansButton {
  margin-top: utils.spacing(-2);
  align-self: center;
}

.loanAmount {
  flex-grow: 1;
}

.loanStatusColor {
  height: 100%;
  width: utils.spacing(1);
  position: absolute;
  top: 0;
  left: 0;
}

.button {
  margin-top: utils.spacing(-1);
  align-self: center;
}

.legend {
  display: grid;
  grid-template-columns: 1fr;
  gap: utils.spacing(2);
}

.chartContainer {
  display: grid;
  grid-template-columns: 1fr 200px;
  gap: utils.spacing(2);
  margin-top: utils.spacing(1);
}

.chartPaper {
  padding: utils.spacing(0.5);
  position: relative;
}

.legendItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: utils.spacing(1, 2, 0, 2);
  border-radius: var(--app-border-radius);
  overflow: hidden;
  position: relative;

  .buttonContainer {
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: opacity 250ms ease,
                visibility 0ms ease;
    height: calc(100% - #{utils.spacing(4)});
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    text-align: center;
  }

  &:hover .buttonContainer {
    visibility: visible;
    opacity: 1;
  }
}

.legendItemPercentage {
  margin-top: utils.spacing(-0.5);
}

.color {
  width: calc(100% + #{utils.spacing(4)});
  height: utils.spacing(1);
  margin-top: utils.spacing(-1);
}

.tradeLockDateIconTypography {
  margin-left: auto;
}

.allocationSection {
  padding: utils.spacing(1, 2, 2, 2);
}
