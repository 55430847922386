@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 1, 1, 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  padding: utils.spacing(1);
  padding-bottom: 1px;
}
