@use '@tsp-ui/core/sass/utils';

.paperTooltip {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: var(--app-color_text);
  box-shadow: var(--shadow-10);
  padding: utils.spacing(2);
  font-size: 16px;
}

.checklistTooltip {
  padding: utils.spacing(2);
  gap: utils.spacing(0.5);
}
