@use '@tsp-ui/core/sass/utils';

.root {
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: utils.spacing(2, 3);
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}

.sectionLabel {
  margin-bottom: utils.spacing(-0.5);
}

.omitLabel {
  margin-top: utils.spacing(1);
}

.activeLiabilities {
  grid-row: 1 / span 2;
}

.rightAlign {
  text-align: right;
}

.bold {
  font-weight: 500;
}

.summary {
  padding: utils.spacing(1);
  justify-self: end;
  display: flex;
  align-items: flex-end;
  gap: utils.spacing(3);
}

.plus {
  color: var(--app-color_success);
}

.minus {
  color: var(--app-color_error);
}

.minusLetterSpacing {
  letter-spacing: 2px;
}
