@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 10vw 1fr;
  gap: utils.spacing(4);

  & > :nth-child(1) {
    align-self:start;
  }

  & > :nth-child(2) {
    grid-column: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: utils.spacing(3);
  }
}
