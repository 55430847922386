@use '@tsp-ui/core/sass/utils';

.tableContainer {
  margin-top: utils.spacing(2);
  overflow: hidden;

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    background-color: var(--app-color_background);

    .noContentPaper {
      width: 250px;
      display: flex;
      flex-direction: column;
      gap: utils.spacing(1);
      align-items: center;
      padding: utils.spacing(2, 2, 1, 2);
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead th {
    font-weight: 500;
    background-color: var(--app-color_background-medium);
    white-space: nowrap;
    padding: utils.spacing(0.5, 2);

    &:not(:last-child) {
      border-right: 1px solid white;
    }

    &.loanPropertyValueCell {
      font-weight: 400;
      background-color: var(--app-color_background);
      border-top: 1px solid white;
      border-right: 1px solid var(--app-color_border);
      border-bottom: 1px solid var(--app-color_border);
      min-width: 102px;
      min-height: 34px;

      &:last-child {
        border-right-width: 0;
      }
    }
  }

  th.loanPropertyValueCell.hideBottomBorder {
    border-bottom-width: 0;
  }

  tbody th {
    padding: utils.spacing(0.5, 2);
    font-weight: 400;
    background-color: var(--app-color_background);
    border-right: 1px solid var(--app-color_border);
    border-bottom: 1px solid var(--app-color_border);
  }

  tbody tr:last-child th {
    border-bottom-width: 0;
  }

  tbody td:last-child {
    width: 0;
  }

  td {
    padding: utils.spacing(0.5, 2);

    &:not(:last-child) {
      border-right: 1px solid var(--app-color_border);
    }
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid var(--app-color_border);
  }
}

.tableHelperText {
  margin-top: utils.spacing(2);
  justify-content: center;
}

.rangeEditCellPopover {
  width: 170px;
  padding: utils.spacing(1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(1);

  & :first-child {
    grid-column: 1/-1;
  }
}

.fullWidth {
  grid-column: 1/-1;
}

.operatorMenuItem {
  text-align: center;

  & > div {
    width: 100%;
  }
}
