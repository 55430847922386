@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(1);
  justify-content: space-between;
  align-items: center;
  padding: utils.spacing(0.5, 1);

  &.active {
    background-color: rgb(237, 247, 237);
  }

  &.pending {
    background-color: rgb(255, 244, 229);
  }

  &.expired {
    background-color: rgb(253, 237, 237);
  }
}
