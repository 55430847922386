@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(1);
}

.adminBadge {
  margin-left: utils.spacing(0.75);
  vertical-align: text-top;
}

.actionsContainer {
  white-space: nowrap;
}

.rolesContainer {
  display: flex;
  align-items: baseline;
}
