@use '@tsp-ui/core/sass/utils';

.root.root { // Increase specificity to override root styles from ManualLoanEntryStep
  width: 750px;
}

.borrowerContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  padding: utils.spacing(3, 2, 2, 2);
}

.label {
  position: absolute;
  top: 0;
  left: utils.spacing(1);
  transform: translateY(-50%);
  padding: utils.spacing(0, 1);
  background-color: white;
}

.switchContainer {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.removeButton {
  justify-self: end;
  align-self: end;
}
