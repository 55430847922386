@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.textFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: start;
  gap: utils.spacing(2);
}

.sellerFields {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.addSeller {
  grid-column: 2;
}

.button {
  align-self: flex-end;
}

