@use '@tsp-ui/core/sass/utils';

.main {
  display: flex;
  flex-direction: column;
  padding-bottom: utils.spacing(3);
}

.root {
  display: grid;
  grid-template-columns: 70% 30%;
  gap: utils.spacing(3);
  flex: 1 1 auto;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  //TODO post-demo make this less fragile
  height: calc(100vh - 154px - #{utils.spacing(3)});
  overflow: auto;
  padding-right: utils.spacing(1);
}

.sidebar {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: utils.spacing(3);
  align-items: start;
}

.loans {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding: utils.spacing(2);
}
