.root {
  width: 40px;
  align-self: flex-end;

  fill: var(--app-color_primary);
  stroke: var(--app-color_primary);
  fill-opacity: 0.5;
  stroke-opacity: 0.5;

  &.or {
    fill: var(--app-color_secondary);
    stroke: var(--app-color_secondary);
  }

  &.disabled {
    fill: var(--app-color_disabled_border);
    stroke: var(--app-color_disabled_border);
    fill-opacity: 1;
    stroke-opacity: 1;
  }

  &:hover {
    fill-opacity: 1;
    stroke-opacity: 1;
  }
}
