@use '@tsp-ui/core/sass/utils';

.card {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);

  &.expired {
    text-decoration: line-through;
  }
}

.filters {
  margin-bottom: utils.spacing(3);
  display: flex;
  gap: utils.spacing(3);
  align-items: flex-end;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: utils.spacing(2);
  align-items: center;
}

.chips {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  justify-content: center;
}

.chip {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.25, 1);
}

.iconButton {
  margin: utils.spacing(-1);
}

.commitmentContainer {
  margin: utils.spacing(0, 2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
}

.datesRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showInactive {
  margin-left: auto;
}
