@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  width: 60px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
}
