@use '@tsp-ui/core/sass/utils';

.filterContainer {
  display: flex;
  gap: utils.spacing(2);
  justify-content: space-between;
  align-items: center;
}

.root {
  display: flex;
  gap: utils.spacing(3);
  margin-top: utils.spacing(3);
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  margin-top: utils.spacing(1);
}

.showOptionalCheckbox {
  margin: -5px; // Counteract extra padding around checkbox
  margin-right: utils.spacing(0.5);
}
