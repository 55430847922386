@use '@tsp-ui/core/sass/utils';

.relevantDocs {
  padding: utils.spacing(0.5, 1);
  margin-bottom: utils.spacing(2);
}

.files {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leftPanel {
  background-color: rgba(149, 65, 113, 0.08); // TODO post-demo theme
  border-radius: var(--app-border-radius);
  padding: utils.spacing(2);
  margin-left: utils.spacing(-2);
}

.allConditionsStep {
  margin-bottom: utils.spacing(2);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}
