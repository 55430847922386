@use '@tsp-ui/core/sass/utils';

.questionCard {
  padding: utils.spacing(2, 2, 1, 2);
  display: inline-flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
}

.amountFields {
  margin: utils.spacing(1, 0);
  display: flex;
  gap: utils.spacing(2);
}
