@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(1, 2);
  padding: utils.spacing(1, 2);
}

.gridCol2 {
  grid-column: 2;
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(0, -2, 1, -2);
}

.diffCheckbox {
  justify-self: end;
  margin-right: 0;
}

.table {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: auto auto auto;
}

.row {
  display: contents;

  & > * {
    padding: utils.spacing(0.5, 1);
    border: 1px solid transparent;
    border-radius: var(--app-border-radius);
  }

  &:hover > * {
    border-color: var(--app-color_border);
    background-color: var(--app-color_background);
  }

  & > :not(:first-child) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > :not(:last-child) {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.buttons {
  display: contents;

  & > * {
    margin-top: utils.spacing(1);
    justify-self: center;
  }
}
