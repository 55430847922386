@use '@tsp-ui/core/sass/utils';

.highLevelCard {
    padding: utils.spacing(0.5, 1);
    display: flex;
    flex-direction: column;
    gap: utils.spacing(0.5);
    overflow: hidden;
}

.highLevelProperty {
    padding-left: utils.spacing(1);
    background-color: var(--app-color_background-medium);
    display: flex;
    gap: utils.spacing(0.5);
    align-items: center;
    justify-content: space-between;
    margin: utils.spacing(-0.5, -1, 0, -1);
    line-height: 1;
    flex: 1;
    min-width: 150px;

    &.readOnly {
        padding: utils.spacing(.5, 0, .5, 1);
    }
}

.editProperty {
    padding: utils.spacing(0, 0, 0, 1);
}

.flexOne {
    flex: 1;
    min-width: 125px;
}

.dropdown {
    padding: utils.spacing(.5, 1, 0);
}

.valueDisplay {
    margin-top: utils.spacing(-0.5);
}
