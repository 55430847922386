@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(1);
    width: fit-content;
}

.formContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    width: 375px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: utils.spacing(1);
}

.displayHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: utils.spacing(-1);
}