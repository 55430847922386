@use '@tsp-ui/core/sass/utils';

.dialogCard {
  width: 400px;
  margin-inline: utils.spacing(-2);
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
}
