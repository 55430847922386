@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  overflow: hidden;
  padding: utils.spacing(4);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: utils.spacing(2);
  margin-top: utils.spacing(4);
}
