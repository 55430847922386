@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(0.5, 2, 1, 2);

  &.hasOverlay {
    align-self: stretch;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(1);
  margin-bottom: utils.spacing(0.5);
}

.eligibilityBlurb b {
  font-weight: 500;
}

.overlayContainer {
  margin-bottom: utils.spacing(1);
}

.overlaySectionHeader {
  margin-top: utils.spacing(1);
}

.overlayList {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.buttons {
  display: flex;
  gap: utils.spacing(1);
  margin-inline: utils.spacing(-1);
}
