@use '@tsp-ui/core/sass/utils';

.root {
  overflow: hidden;

  &:last-child .divider {
    display: none;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: utils.spacing(0.5);
  padding: utils.spacing(1, 2, 0.5, 2);

  &_appear, &_enter {
    opacity: 0;
    transform: translateY(-120%);
    transition: transform 250ms ease,
                opacity 250ms ease;

    &_active {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &_exit {
    opacity: 1;
    transform: translateY(0);
    transition: transform 250ms ease,
                opacity 150ms ease;

    &_active {
      opacity: 0.2;
      transform: translateX(120%);
    }
  }
}

.row {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: utils.spacing(4);
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
  margin-block: utils.spacing(-1);
  align-self: center;
}

.newChip {
  color: var(--app-color_primary);
  border-color: var(--app-color_primary);
}

.divider {
  margin-inline: utils.spacing(1);
}

