@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.spacing(3);
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .addButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}
