@use '@tsp-ui/core/sass/utils';

.tableRow td {
  padding-top: utils.spacing(.5) !important;
  padding-bottom: utils.spacing(.5) !important;
}

.rowInactive td {
  text-decoration: line-through;
  color: gray;
}

.feeAmount {
  text-align: right;
  white-space: nowrap;
}

.adjustmentField input {
  font-size: 14px;
  text-align: right;
}

.adjustedIcon {
  font-size: inherit;
  vertical-align: text-top;
  margin-right: utils.spacing(.5);
}

.adjusted {
  white-space: nowrap;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}