@use '@tsp-ui/core/sass/utils';

.addressTypography {
  margin-bottom: utils.spacing(1);
}

.addressFields {
  width: 550px;
  align-self: center;
}

.nextButton {
  align-self: flex-end;
}

.attachedQuestion {
  margin-top: utils.spacing(1);
}
