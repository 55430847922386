@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';
@use './components/MainNav/MainNav.module';

.root {
  height: 100vh;
  display: grid;
  grid-template:
        "nav header" #{premicorr-theme.$header-height}
        "nav main"/#{MainNav.$drawerWidth} 1fr;
}

.headerContainer {
  grid-area: header;
  background-color: white;
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2);
}

.toolbar {
  height: #{premicorr-theme.$header-height};
  margin: utils.spacing(0, 3);
  gap: utils.spacing(3);
}

.logoContainer {
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.logo {
  height: 44px;
}

.customerName {
  padding: utils.spacing(0.5, 1);
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background-medium);
}
