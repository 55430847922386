@use '@tsp-ui/core/sass/utils';

.stepper {
  width: 750px;
  padding: utils.spacing(0, 3);
  margin-bottom: utils.spacing(4);
}

.optionalCaption {
  margin: utils.spacing(-0.5, 0, 0, 1);
}
