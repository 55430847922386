@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textFields {
  display: flex;
  gap: utils.spacing(2);
}
