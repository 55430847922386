@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.chips {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(1);
  margin-top: utils.spacing(.5);
}

.buttons {
  margin-block: utils.spacing(-1);
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
}

.inactive {
  text-decoration: line-through;
}

.progress {
  margin: utils.spacing(.5, 1, 0, 0);
}
