@use '@tsp-ui/core/sass/utils';

.latePaymentFields {
  display: flex;
  gap: utils.spacing(2);
  margin: utils.spacing(1, 0, 1, 0);

  & > :global(.MuiTextField-root) {
    width: 130px;
  }
}

.questionCard { //TODO
  padding: utils.spacing(2, 2, 1, 2);
  display: inline-flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
}
