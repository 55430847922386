@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1.5);
}

.filters {
  flex: 0 0 auto;
  margin-top: utils.spacing(-1);
  padding-bottom: utils.spacing(2);
  overflow: hidden;
  border-bottom: 1px solid var(--app-color_border);
}

.mainContent {
  background-color: var(--app-color_background);
}

.dialogActions {
  border-top: 1px solid var(--app-color_border);
  justify-content: center;

  :global(.MuiPaginationItem-previousNext) {
    color: var(--prem-color_text-secondary);
  }
}

.form {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: utils.spacing(2);
}

.sectionLabel {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  padding: utils.spacing(0.25, 1);
}

.expandButton {

}
