@use '@tsp-ui/core/sass/utils';

.root {
  width: 550px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);

  .dbaContainer {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
    grid-column: 1/-1;
  }

  .dbaRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    align-items: center;
  }

  .fullWidth {
    grid-column: 1/-1;
  }
}
