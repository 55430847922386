@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.radios {
  margin-left: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

strong {
  font-weight: 500;
}
