@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15vw;
}

.disabledCheckbox {
  margin-block: utils.spacing(-1);
}
