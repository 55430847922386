@use '@tsp-ui/core/sass/utils';

.sections {
  margin-top: utils.spacing(4);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(3);
}

.filters {
  display: flex;
  gap: utils.spacing(4);
  align-items: center;
  justify-content: space-between;

  &.justifyStart {
    justify-content: flex-start;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
