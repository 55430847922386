@use '@tsp-ui/core/sass/utils';

.card {
  padding: utils.spacing(1, 1, 1, 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardButtons {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
  white-space: nowrap;
}
