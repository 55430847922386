@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  min-width: 300px;
  max-width: 400px;
}

.agencyButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.yesNoButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.agencyOrNonAgencyButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.agencyOrNonAgencyButton {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);
}

.selectableButton {
  color: var(--app-color_text-secondary);

  &.selected {
    background-color: var(--app-color_background);
    color: var(--app-color_secondary);
    box-shadow: 0 0 0 1px var(--app-color_secondary);
  }
}

.icon {
  font-size: utils.spacing(5);
}

.help {
  align-self: center;
}

.formFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.divider {
  margin-inline: utils.spacing(-1.5);
}

.investorDetailsLabel {
  margin-top: utils.spacing(1);
}
