@use '@tsp-ui/core/sass/utils';

.wrapper {
  position: absolute;
}

.button {
  width: 40px;
  min-width: 0;
  padding: utils.spacing(0, 0.5);
  background: white;
}

.pseudoDisable {
  pointer-events: none;
}
