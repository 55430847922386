@use '@tsp-ui/core/sass/utils';

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.card {
  width: 400px;
  padding: utils.spacing(0, 1, 1, 1);
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: 500;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  align-items: center;
}

.iconButtons {
  display: flex;
  gap: utils.spacing(0.5);
  justify-content: flex-end;
  margin-right: utils.spacing(-1);
}

.question {
  margin-top: utils.spacing(2);
  align-self: center;

  & > :global(.MuiTypography-root) {
    max-width: 300px;
  }
}

