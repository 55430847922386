@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: grid;
  grid-template-columns: 1fr auto;

  justify-content: space-between;
  gap: utils.spacing(0.5, 1);
  overflow-wrap: anywhere;
}

.primaryContactIcon {
  margin-right: utils.spacing(1);
}

.description {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.actionsContainer {
  white-space: nowrap;
  margin: utils.spacing(-1, 0);
}

.contactInfoContainer {
  grid-column: 1/-1;
}
