@use '@tsp-ui/core/sass/utils';

.limitDisplay {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  color: rgba(0, 0, 0, 0.2);

  .progress {
    grid-column: 1/-1;
  }

  .limitAmounts {
    opacity: 0;
  }

  &:hover {
    color: var(--app-color_primary);
  }

  &:hover .limitAmounts {
    opacity: 1;
  }
}