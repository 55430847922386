@use '@tsp-ui/core/sass/utils';

.notificationsMenu {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 70vh;

  h6 {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
  }
}

.notifications {
  padding-block: utils.spacing(0.5);
  overflow: auto;
}

.divider {
  margin-inline: utils.spacing(-1);
}

.upToDate {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(1);
}
