@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(3);
  position: relative;

  & > *:nth-child(even):last-child {
    grid-column: 1/-1;
    justify-self: center;
    min-width: calc(50% - #{utils.spacing(1.5)});
  }
}

.partiesQuestion {
  grid-column: 1/-1;
  justify-self: center;
}

.labelGroup {
  margin-top: utils.spacing(0.25);
}

.card {
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
  border: none;
  padding: 0;
}

.labeledPaper {
  padding: utils.spacing(2);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  justify-content: center;
}

.floatingLabel {
  position: absolute;
  top: 0;
  left: utils.spacing(1);
  transform: translateY(-50%);
  background-color: white;
  padding: utils.spacing(0, 1);
}
