@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 400px auto;
  gap: utils.spacing(4);
}

.accounts {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.summaryContainer {
  display: flex;
  flex-direction: column;
}

.sectionLabel {
  margin-bottom: utils.spacing(-1);

  &:not(:first-child) {
    margin-top: utils.spacing(1);
  }
}

.summary {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(6);
}

.summaryButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sufficientCash {
  margin-top: auto;
}

.doneButton {
  margin-top: utils.spacing(2);
}

.summaryExtraMargin {
  margin-top: utils.spacing(2);
}

.rightAlign {
  text-align: right;
}

.centerAlign {
  text-align: center;
}

.bold {
  font-weight: 500;
}
