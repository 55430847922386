@use '@tsp-ui/core/sass/utils';

.alert {
  width: 'max-content';
}

.card {
  padding: utils.spacing(1);
  width: 100%;
  display: flex;
  justify-Content: space-between;
  margin-top: utils.spacing(1);

  & :last-child {
    display: flex;
    align-items: center;
  }

}

.progress {
  padding: utils.spacing(1);
}
