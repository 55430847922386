@use '@tsp-ui/core/sass/utils';

.root {
  width: 900px;
}

.content {
  display: flex;
  gap: utils.spacing(4);
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
