@use '@tsp-ui/core/sass/utils';

.stepperContent {
  padding-block: 0;
}

.stepper {
  width: 350px;
  margin-inline: auto;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: utils.spacing(2);
  margin-top: utils.spacing(3);
}
