@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  gap: utils.spacing(6);
  align-items: start;
  grid-template-columns: auto 350px 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(3);
  }

  & > div:nth-child(3) {
    justify-self: end;
  }
}

.clientDisplay {
  margin-bottom: utils.spacing(1);
}

.users {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1.5);
}

.user {
  display: flex;
  flex-direction: column;

  & > :global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
  }
}

