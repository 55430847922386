@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 1, 1, 2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code {
  margin-left: utils.spacing(1);
}

.contactMenu {
  padding: utils.spacing(2);
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contactButton {
  margin-top: utils.spacing(-1);
}

.nmlsID {
  margin-right: utils.spacing(1);
}
