@use '@tsp-ui/core/sass/utils';

.mainRow {
  display: flex;
  width: 100%;
  align-items: center;
  gap: utils.spacing(4);
}

.pricingControls {
  display: flex;
  gap: utils.spacing(2);
  margin-bottom: utils.spacing(2);

  .expirationDate {
    margin-top: 5px;
  }

  .commitmentType {
    margin-left: auto;
  }
}

.loanNumber {
  display: flex;
  align-items: center;

  & > button {
    margin-left: utils.spacing(1);
    margin-right: utils.spacing(-1);
  }

  & > b {
    font-weight: 500;
  }
}

// Renders a hidden $ to align interest rate and loan amt
.hidden {
  visibility: hidden;
}

.pricingDetails {
  margin-left: auto;
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.noProductsFound {
  margin-bottom: utils.spacing(1);
}

.initialLoader {
  margin: utils.spacing(.5, -2, -1, -7);
}

.alert {
  margin: utils.spacing(-1, -1, 0, -6);
}

.ineligibleProductsButton {
  margin-top: utils.spacing(1);
}

.pricingResultCardTransition_enter {
  opacity: 0;
  transform: translateY(-100%);

  transition: opacity 150ms ease,
  transform 250ms ease;

  &_active {
    opacity: 1;
    transform: none;
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      transition-delay: #{50 * $i}ms;
    }
  }
}
