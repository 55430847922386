@use '@tsp-ui/core/sass/utils';

.permissions {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: stretch;
  margin-top: utils.spacing(2);
}

.row {
  display: contents;

  &>* {
    padding: utils.spacing(1);
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
  }

  &> :first-child {
    margin-left: calc(#{utils.spacing(-1)} - 1px);
    border-left: 1px solid transparent;
    border-top-left-radius: var(--app-border-radius);
    border-bottom-left-radius: var(--app-border-radius);
  }

  &> :last-child {
    margin-right: calc(#{utils.spacing(-1)} - 1px);
    border-right: 1px solid transparent;
    border-top-right-radius: var(--app-border-radius);
    border-bottom-right-radius: var(--app-border-radius);
  }

  &:not(.headerRow):hover>* {
    border-color: var(--app-color_border);
  }
}

.sectionHeader {
  grid-column: 1/-1;
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.5, 1);
  font-weight: 500;
  margin: utils.spacing(1, -1, 0, -1);
}

.permissionTypography {
  display: flex;
  align-items: center;
}

.radioGroup {
  grid-column: 2/-1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  & label {
    display: flex;
    justify-content: center;
  }

  &> :last-child {
    justify-content: right;
  }
}