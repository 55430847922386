@use '@tsp-ui/core/sass/utils';

.detailsStep {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  .dateFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
  }
}

.matrixStep {
  min-width: 600px;

  .columnAutocomplete {
    margin-top: utils.spacing(2);
  }
}
