@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}

.description {
  margin-bottom: utils.spacing(0.5);
}

.exclusionDetails {
  background-color: white;
  color: var(--app-color_text);
  box-shadow: var(--shadow-3);
  max-width: 500px;
  padding: utils.spacing(1, 2);
}

.popover {
  padding: utils.spacing(1);
}
