@use '@tsp-ui/core/sass/utils';

.root {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  &.targetStep {
    width: 500px;
    align-items: center;
  }

  &.exclusionStep {
    width: auto;
  }
}

.dateFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.autocomplete {
  width: 400px;
}

.exclusions {
  align-self: center;
  padding: utils.spacing(1, 2);
}
