@use '@tsp-ui/core/sass/utils';

.delNonDelSwitch {
  margin-left: -3px;
}

.delNonDelLimitFields {
  margin-right: utils.spacing(1);
}

.productTypeCardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  margin-top: utils.spacing(2);
}
