@use '@tsp-ui/core/sass/utils';

.dialogContent {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: utils.spacing(6);
  padding-bottom: utils.spacing(6);

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
  }
}

.statusContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(3);
  color: var(--app-color_text-secondary);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: utils.spacing(0.5);
    padding: utils.spacing(1, 2, 0.5, 2);
  }
}

.outstandingErrors {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.revFileHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.revisionFileInput {
  margin-bottom: utils.spacing(1) !important;
}

.downloadAndUpdateCaption {
  margin-inline: auto;
  width: 250px;
}

.progressTimeline {
  position: relative;
  padding: 0;
  margin-top: utils.spacing(-1);
}

.timelineContent {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;
}

.hiddenConnector {
  visibility: hidden;
}

.showCreateRevisionButton {
  display: block;
  margin-left: -5px;
  margin-top: utils.spacing(-0.5);
}
