@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 0);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  width: 750px;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
}

.expandButton {
  align-self: flex-end;
  padding: 0;
}
