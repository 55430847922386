@use '@tsp-ui/core/sass/utils';

.productTypeCard {
  padding: utils.spacing(1);

  .mainRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: utils.spacing(2);
  }

  .expandedRow {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);

    margin: utils.spacing(1, -1, 0, -1);
    padding: utils.spacing(2, 1, 0, 1);
    border-top: 1px solid var(--app-color_border);
    box-shadow: inset 0 6px 6px rgb(0 0 0 / 2%);
  }

  .productTypeControls {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: utils.spacing(2);
    margin-bottom: utils.spacing(1);
  }
}

.limitFields {
  width: 350px;
}