@use '@tsp-ui/core/sass/utils';

.paper {
  width: 100%;
  height: 100%;
}

.title {
  display: grid;
  grid-template-columns: auto 200px;
  gap: utils.spacing(3);
  align-items: center;
}

.content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: utils.spacing(4);
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
  align-items: flex-start;
}

.frame {
  border: 1px solid var(--app-color_border);
  border-radius: var(--app-border-radius);
  width: 100%;
  height: 100%;
}
