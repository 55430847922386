@use '@tsp-ui/core/sass/utils';

.card {
  padding: utils.spacing(1, 1, 1, 2);
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: utils.spacing(1);
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}

.cardButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: utils.spacing(-1);
  margin-bottom: utils.spacing(-1);
}

.expirationDate {
  justify-self: end;
  align-self: end;
}
