@use '@tsp-ui/core/sass/utils';

.card {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &.expired {
    text-decoration: line-through;
  }

  &.inDropZone {
    border-color: transparent;

    * {
      pointer-events: none !important;
    }
  }

  &.inDropZone .dropMask {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: var(--app-color_background-medium);
    border-radius: var(--app-border-radius);
    border: 2px dashed var(--app-color_secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: utils.spacing(1);
  }
}

.filters {
  margin-bottom: utils.spacing(3);
  display: flex;
  gap: utils.spacing(3);
  align-items: flex-end;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.progressLabel {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: utils.spacing(2);
  align-items: baseline;
  padding-bottom: utils.spacing(0.5);

  :last-child {
    justify-self: end;
  }

  &.withExpandIcon {
    grid-template-columns: auto 1fr auto 1fr;
  }
}

.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: utils.spacing(2);
  align-items: center;
}

.chips {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}

.chip {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.25, 1);
}

.iconButton {
  margin: utils.spacing(-1);
}

.commitmentContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: utils.spacing(2);
}

.linearProgress {
  margin: utils.spacing(0, -1, -1, -1);
  height: utils.spacing(0.75);

  &.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
}

.linearProgressIconButton {
  margin: utils.spacing(-1.5, -1, -2, -1);
  position: relative;
  top: utils.spacing(0.5);
}

.datesRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.showInactive {
  margin-left: auto;
}

.popoverPaper {
  background-color: var(--app-color_background);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  padding-block: utils.spacing(2);
}

.updatedAmountOuter {
  display: flex;
  gap: utils.spacing(1);
  align-items: baseline;
}

.updatedAmountTypography {
  color: var(--app-color_success);
  position: absolute;
  top: 6px;
  font-weight: 500;
}

.updatedAmountContainer {
  position: relative;
  height: 20px; // TODO post-demo

  &:first-child .updatedAmountTypography {
    right: 0;
  }
}

.deliveryCompleteContainer {
  position: relative;
  height: 20px;

  width: 157px; // TODO post-demo
}

.deliveryCompleteTypography {
  position: absolute;
  white-space: nowrap;
}
