@use '@tsp-ui/core/sass/utils';

.notification {
    margin-top: utils.spacing(1);
    padding: utils.spacing(1);
    display: flex;
    gap: utils.spacing(2);
    align-items: center;
    justify-content: space-between;
}

.progress {
    padding: utils.spacing(1);
    padding-bottom: 1px;
}

.buttons {
    display: flex;
    justify-content: center;
}

.notificationIcons {
    display: flex;
    gap: utils.spacing(0.5);
}