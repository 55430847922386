@use '@tsp-ui/core/sass/utils';

.button {
  position: absolute;
  right: -8px;
  opacity: 0;

  &:hover, &:focus {
    opacity: 1;
  }
}
