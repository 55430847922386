@use '@tsp-ui/core/sass/utils';

.form {
  display: grid;
  grid-template-columns: 190px 190px;
  gap: utils.spacing(2);
}

.fullWidth {
  grid-column: 1/-1;
}
