@use '@tsp-ui/core/sass/utils';

.accountMenu {
  padding: utils.spacing(1, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  &.noAccount {
    padding-top: utils.spacing(2);
  }
}

.nameContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.accountContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;

  margin: utils.spacing(-1, -2, 0, -2);
  padding: utils.spacing(0.5, 2);
  background-color: var(--app-color_primary);
  color: white;
}

.switchAccountButton {
  margin-block: utils.spacing(-1);
}

.avatar {
  background-color: var(--app-color_primary);
}

.button {
  color: var(--app-color_text-secondary);
}

.iconButton {
  margin-left: auto;
}

.logOut {
  align-self: flex-end;
  margin-top: utils.spacing(-0.75);
  margin-right: utils.spacing(-1);
}
