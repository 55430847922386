@use '@tsp-ui/core/sass/utils';

.root {
    width: 550px;
    padding: utils.spacing(4, 4, 2, 4);
    display: flex;
    flex-direction: column;
    gap: utils.spacing(4);
    margin-bottom: utils.spacing(4);
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: utils.spacing(2);
}
