@use '@tsp-ui/core/sass/utils';

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  max-width: 700px;
}

.fieldsHeader {
  margin-bottom: utils.spacing(-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: var(--app-color_text-secondary);
}

.tierFieldRow {
  display: grid;
  grid-template-columns: repeat(4, 1fr) auto;
  gap: utils.spacing(2);
}

.removeButton {
  margin-left: utils.spacing(-1);
}
