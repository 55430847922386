@use '@tsp-ui/core/sass/utils';

.showInactive {
  margin-right: 0;
}

.iconButton {
  margin-left: auto;
}

.productSetHeader {
  display: flex;
  align-items: center;
  margin-block: utils.spacing(-1);

  &:not(:nth-child(2)) {
    margin-top: 0;
  }
}

.headerIconButton {
  margin-block: utils.spacing(-1);
}

