@use '@tsp-ui/core/sass/utils';

.notes {
    white-space: pre-wrap;
    grid-column: 1/-1;
}

.group {
    padding: utils.spacing(1);
    row-gap: utils.spacing(1);
}
