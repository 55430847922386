@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.actions {
  white-space: nowrap;
}
