@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: utils.spacing(4);
}

.percentageContent {
  display: grid;
  gap: utils.spacing(2);
  grid-template-columns: 1fr 1fr;
}

.loanAmount {
  flex: 1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.percentageInput {
  width: 65px;
}

.firstRow {
  display: flex;
  gap: utils.spacing(1);
  grid-column: span 2;
  align-items: flex-end;
}

.percentage input {
  padding-top: utils.spacing(1);
  text-align: right;
}

.separationText {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: utils.spacing(1.125);
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.feesTable {
  margin-top: utils.spacing(-1);
}

.divider {
  background-color: transparent !important;
  border: none !important;
}

.additionalFees {
  margin-bottom: utils.spacing(.25);
}
