@use '@tsp-ui/core/sass/utils';

.content {
  display: flex;
  gap: utils.spacing(4);
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
