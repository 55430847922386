@use '@tsp-ui/core/sass/utils';

.productsTable {
    margin-top: utils.spacing(1);
    margin-bottom: utils.spacing(1);
}

.tableRow td {
    padding-top: utils.spacing(.5) !important;
    padding-bottom: utils.spacing(.5) !important;
}

.description {
    text-decoration: none !important;
    white-space: nowrap;
}