@use '@tsp-ui/core/sass/utils';

.matrixDialog {
  height: 100%;
}

.tabContent {
  flex: 0 0 auto;
  padding-block: 0;
  margin-block: utils.spacing(-1);
}

.matrixContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.gridContainer {
  flex: 1 1 auto;
  display: flex;
  margin-top: utils.spacing(2);
}

.exclusionContainer {
  padding: utils.spacing(2);
  background-color: var(--app-color_background);
}

.exclusionCardContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  flex-wrap: wrap;
  margin-top: utils.spacing(2);
  justify-content: center;
}

.exclusionCard {
  padding: utils.spacing(1);
}

.logicContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.button {
  margin-left: utils.spacing(-1);
  margin-top: utils.spacing(0.5);
}

.noExclusions {
  margin-top: utils.spacing(0.5);
}
