@use '@tsp-ui/core/sass/utils';

.detailsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);

  .fullWidth {
    grid-column: 1/-1;
  }

  .switch {
    grid-column-start: 1;
    align-self: start;
  }
}

.switchFields {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}
