@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  gap: utils.spacing(2);
  align-items: start;
  grid-template-columns: auto 1fr;
}

.background {
  background-color: var(--app-color_background);
}

.detailsRows {
  align-items: center;
}

.chips {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(1);
}

.colorSuccess {
  color: var(--app-color_success);
}

.colorAction {
  color: var(--app-color_action);
}

.valueContainer {
  display: flex;
  gap: utils.spacing(.5);
}
