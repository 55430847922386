@use "sass:color";

.clientTheme {
  --app-color_primary: #954171;
  --app-color_primary-light: #c86f9f;
  --app-color_primary-dark: #651146;
  --app-color_primary_very-dark: #{color.adjust(#651146, $blackness: 20%)};

  --app-color_secondary: #419565;
}
