@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';
@use 'sass:color';
/*
  This file contains overrides for css variables defined by amplify to style
  the auth components according to the premicorr theme.
 */

[data-amplify-theme] {
  --amplify-colors-brand-primary-10: #{color.scale(premicorr-theme.$primary-color, $lightness: 70%)};
  --amplify-colors-brand-primary-20: #{color.scale(premicorr-theme.$primary-color, $lightness: 60%)};
  --amplify-colors-brand-primary-30: #{color.scale(premicorr-theme.$primary-color, $lightness: 50%)};
  --amplify-colors-brand-primary-40: #{color.scale(premicorr-theme.$primary-color, $lightness: 40%)};
  --amplify-colors-brand-primary-50: #{color.scale(premicorr-theme.$primary-color, $lightness: 30%)};
  --amplify-colors-brand-primary-60: #{color.scale(premicorr-theme.$primary-color, $lightness: 20%)};
  --amplify-colors-brand-primary-70: #{color.scale(premicorr-theme.$primary-color, $lightness: 10%)};
  --amplify-colors-brand-primary-80: var(--app-color_primary);
  --amplify-colors-brand-primary-90: #{color.scale(premicorr-theme.$primary-color, $blackness: 10%)};
  --amplify-colors-brand-primary-100: #{color.scale(premicorr-theme.$primary-color, $blackness: 20%)};
  --amplify-fonts-default-static: var(--app-font-family);
  --amplify-fonts-default-variable: var(--app-font-family);
  --amplify-components-authenticator-router-border-width: 0;
}

/* Hide skip button on verify user screen */
[data-amplify-authenticator-verifyuser] button[data-variation=link] {
  display: none;
}

.amplify-button[data-variation=primary] {
  font-weight: 500;
  text-transform: uppercase;
}

[data-amplify-router] {
  border-radius: var(--app-border-radius);
}

.custom-sign-in-header {
  padding-top: var(--amplify-space-xl);
  padding-left: var(--amplify-space-xl);
}
