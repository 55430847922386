@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  gap: utils.spacing(6);
  align-items: start;
  grid-template-columns: auto 350px 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(3);
  }

  & > div:nth-child(3) {
    justify-self: end;
  }
}
