@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: utils.spacing(1);
}

.investorName {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}

.lineThrough {
  text-decoration: line-through;
  color: gray;
  
  &:hover {
    text-decoration: underline line-through;
  }
}
