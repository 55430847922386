@use '@tsp-ui/core/sass/utils';

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1300px;
  gap: utils.spacing(4);
  align-items: start;

  .helpIcon {
    align-self: center;
    justify-self: start;
  }
}

.allLimits {
  width: 100%;
}
