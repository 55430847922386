@use '@tsp-ui/core/sass/utils';

.perYearRadio {
  margin-right: 0;
}

.questionCard { //TODO
  padding: utils.spacing(2, 2, 1, 2);
  display: inline-flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.feesAndTaxesFields {
  display: flex;
  gap: utils.spacing(2);
}

.dateAndValueFields {
  margin-top: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
}

.nextButton {
  align-self: flex-end;
}

.questionRow {
  display: flex;
  gap: utils.spacing(3);
  align-items: flex-start;
}
