@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 10vw 1fr;
  gap: utils.spacing(4, 0);
  align-items: start;

  & > div:nth-child(even) {
    grid-column: 3;
  }

  & > div:nth-child(2) {
    justify-self: end;
  }
}
