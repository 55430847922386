.root {
  position: relative;
  min-width: 100px;

  &:hover .actions {
    opacity: 1;
  }
}

.actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: var(--app-color_background);

  &.show {
    transition: opacity 250ms ease;
    opacity: 1;
    background-color: transparent;
    border: 1px solid var(--app-color_secondary);

    > * {
      opacity: 0;
    }
  }
}
