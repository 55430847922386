@use '@tsp-ui/core/sass/utils';

.root {
  margin-left: utils.spacing(-1);
}

.selected {
  font-weight: 500;
}

.extraWidth {
  width: calc(100% + #{utils.spacing(1)});
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
