@use '@tsp-ui/core/sass/utils';

.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    width: 500px;
  }
  
.fullWidth {
    grid-column: 1/-1;
}

.amortizationDetailsFields {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.sectionStart {
  margin-top: utils.spacing(2);
}