@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
}

.noBorder {
  border: none;
}

.chip {
  padding: utils.spacing(0.25, 1);
  align-self: flex-start;
  border-top-left-radius: var(--app-border-radius);
  border-top-right-radius: var(--app-border-radius);
  position: relative;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
}

.pending {
  border: 1px solid var(--app-color_warn);
  color: var(--app-color_warn);
}

.active {
  border: 1px solid var(--app-color_success);
  color: var(--app-color_success);
}

.inactive {
  border: 1px solid var(--app-color_text-secondary);
  color: var(--app-color_text-secondary);
  border-radius: var(--app-border-radius);
}

.suspended {
  border: 1px solid darkred;
  color: var(--app-color_text-secondary);
  border-radius: var(--app-border-radius);
}

.checklist {
  margin: utils.spacing(1, 2);
}

.actions {
  padding: utils.spacing(1, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
