@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    white-space: nowrap;
    padding: utils.spacing(0.25, 0.5);
    color: var(--app-color_text-secondary);
    text-decoration: none;

    &:hover {
        cursor: pointer;
        box-shadow: var(--shadow-1);
    }
}