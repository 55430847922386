@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(0.5, 1, 0.5, 1);
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.accountInfo {
  display: flex;
  flex-direction: column;
}

.creditorName {
  font-weight: 500;
}

.liabilityType {
  margin-top: auto;
}

.iconButtons {
  display: flex;
  gap: utils.spacing(0.5);
  justify-content: flex-end;
  margin: utils.spacing(-0.5, 0, 0, 0);
}

.rightAlign {
  text-align: right;
}
