@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 175px 125px 175px;
  gap: utils.spacing(2);
}

.placeholderSelect {
  &::placeholder {
    color: currentColor;
    opacity: 0.42;
  }

  &:placeholder-shown {
    opacity: 1;
    border-color: transparent;
    background: transparent;
  }
}

.minMaxFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}
