@use '@tsp-ui/core/sass/utils';

.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
}

.fullWidth {
  grid-column: 1/-1;
}
