@use '@tsp-ui/core/sass/utils';

.root {
  display: inline-flex;
  flex-direction: column;

  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);

  gap: utils.spacing(2);
  padding: utils.spacing(2);
}

.card {
  min-width: 350px;
  display: flex;
  align-items: center;
  text-decoration: none;

  gap: utils.spacing(4);
  padding: utils.spacing(2);
  transition: all 250ms ease;

  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow-6);
    transform: scale(1.015);
  }

  &.customer {
    padding-block: utils.spacing(1);
  }
}

.logoContainer {
  width: 120px;
  height: 40px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain
  }
}
