@use '@tsp-ui/core/sass/utils';

.diff {
  background-color: var(--app-color_background);
  border: 1px solid var(--app-color_border);
}

.padding {
  padding: utils.spacing(1);
}
