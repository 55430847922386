@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';

$drawerWidth: 85px;

.nav {
  grid-area: nav;
  display: flex;
  z-index: 2;
}

.navGroup {
  position: relative;
  overflow: hidden;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: $drawerWidth;
  height: 100vh;
  background-color: var(--app-color_primary);
  border-right: none;
  box-shadow: var(--shadow-10);
  color: #FFF;

  .navLogo {
    height: 40px;
    margin: utils.spacing(1.5, 0);
  }
}

.navListTransitionContainer {
  position: relative;
  width: 100%;
}

.subNavGroup {
  z-index: 999;
  background-color: var(--app-color_primary-dark);
  color: #FFF;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;

  & > * {
    border-radius: var(--app-border-radius);

    &:nth-child(-n + 3) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    &:nth-child(3n) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:first-child, &:nth-child(3n + 4) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:nth-child(n+10) {
      border-bottom-right-radius: 0;
    }
  }
}

.trackingNavGroup {
  grid-template-columns: 1fr;
}
