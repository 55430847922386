@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(3);
}

.section {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
    width: 375px;
}
