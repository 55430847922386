@use '@tsp-ui/core/sass/utils';

.content {
  display: flex;
  gap: utils.spacing(6);
  align-items: baseline;
  margin-bottom: utils.spacing(1);
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  margin: utils.spacing(0, -1);
}
