@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  gap: utils.spacing(6);
  align-items: flex-start;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: utils.spacing(3);
}
