@use '@tsp-ui/core/sass/utils';

.expirationOptions {
  padding: utils.spacing(1.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(4);
  border: 1px solid var(--app-color_border);
}
