@use '@tsp-ui/core/sass/utils';

.content {
  display: grid;
  grid-template-columns: minmax(320px, max-content) auto;
  gap: utils.spacing(4);
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
