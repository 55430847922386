@use '@tsp-ui/core/sass/utils';

.headerContent {
  flex: 0 0 auto;
  padding-bottom: 0;
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  margin-bottom: utils.spacing(1);
}

.content {
  display: grid;
  grid-template-columns: minmax(250px, auto) auto;
  gap: utils.spacing(6);
}

.column {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  max-width: 500px;
}

// TODO post-demo make a component for this
.header {
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background-medium);
  font-weight: 500;
  padding: utils.spacing(0.5, 2);
}

.section {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: utils.spacing(0, 2);
  padding: utils.spacing(1, 2, 0, 2);
}

.infoHeader {
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.icons {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
}

.showArmButton {
  justify-self: right;
  margin-right: -5px;
}

.armDetails {
  grid-column: 1/-1;
  justify-self: end;
}

.borrowers {
  padding: utils.spacing(1, 2, 0, 2);
  display: flex;
  flex-direction: column;
}

.borrowerCardHeader {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  justify-content: space-between;
}

.borrowerDetails {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: utils.spacing(1);
  margin-top: utils.spacing(0.5);
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(1);
}

.propertyAddress {
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(1);
}

.justifyStart {
  justify-self: start;
}

.justifyEnd {
  justify-self: end;
}
