@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  overflow: hidden;
}

.newNotification {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: utils.spacing(2);
  padding: utils.spacing(1, 2);
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background);
  border: 1px solid var(--app-color_border);
}

.dismissButton {
  margin-block: utils.spacing(-1);
  align-self: center;
}
