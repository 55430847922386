@use '@tsp-ui/core/sass/utils';

.root {
  overflow: hidden;
}

.alert {
  margin: utils.spacing(-1, -1, 0, -6);
}

.expandableContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.fileInputContainer {
  position: relative;
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(2);
}

.addAnotherButton {
  margin-top: utils.spacing(-0.75);
}

.fileUploadLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: var(--app-border-radius);
  display: grid;
  align-items: end;
  overflow: hidden;
}

.initialLoader {
  margin: utils.spacing(.5, -2, -1, -7);
}

.mainRow {
  display: flex;
  width: 100%;
  align-items: center;
  gap: utils.spacing(4);
}

.loanNumber {
  display: flex;
  align-items: center;

  & > button {
    margin-left: utils.spacing(1);
    margin-right: utils.spacing(-1);
  }
}

// Renders a hidden $ to align interest rate and loan amt
.hidden {
  visibility: hidden;
}

.additionalDetails {
  margin-left: auto;
  padding-right: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.docsLoading {
  display: flex;
  align-items: center;
}

.additionalContent {
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
}

.iconTypography {
  gap: utils.spacing(0.5);
  margin-right: utils.spacing(1);
}
