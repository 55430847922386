@use '@tsp-ui/core/sass/utils';

.main {
  padding-bottom: utils.spacing(3);
  max-width: 1600px;
}

.pageHeader {
  display: flex;
  align-items: center;
  gap: utils.spacing(4);
}

.root {
  display: grid;
  grid-template-columns: calc(75% - #{utils.spacing(4)}) 25%;
  grid-template-rows: auto auto;
  align-items: start;
  gap: utils.spacing(4);

  //TODO post-demo make this less fragile
  height: calc(100vh - 154px - #{utils.spacing(3)});
}

.mainContent {
  display: grid;
  gap: utils.spacing(3);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
}

.charts {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 250px 250px 250px;
  justify-content: space-around;
  gap: utils.spacing(0, 3);
  border-top: 1px solid var(--app-color_border);
  padding-top: utils.spacing(1);
  border-bottom: 1px solid var(--app-color_border);
}

.divider {
  margin-inline: utils.spacing(-1);
}

.loans {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
  padding: 0;
  border: 0 !important;
}

.loanRow {
  padding: utils.spacing(0.5, 2);
}

.iconTypography {
  margin-left: auto;
}

.chartContainer {
  position: relative;
}

.chartLabel {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

.alertCard {
  padding: utils.spacing(1);
  display: flex;
  align-items: center;
}

.alertActions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.date {
  flex: 0 0 auto;
  margin-left: auto;
  white-space: nowrap;
  margin-block: utils.spacing(-0.5);
}

.alertIcons {
  display: flex;
  align-items: center;
  gap: utils.spacing(0.5);
  margin-top: -5px;
  margin-right: -5px;
}

.alerts {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
  margin-top: utils.spacing(2);
  padding-inline: utils.spacing(2);
}

// TODO post-demo make a component for this
.header {
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background-medium);
  font-weight: 500;
  padding: utils.spacing(0.5, 2);
}

.activityStream {
  margin-top: utils.spacing(2);
  padding-inline: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.activityStreamBottomRow {
  display: flex;
  align-items: baseline;
  gap: utils.spacing(2);
  justify-content: space-between;
  margin-top: utils.spacing(0.5);
}

.activityStreamItem {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: utils.spacing(2);
}

.avatar {
  background-color: var(--app-color_primary);
}

.activityStreamMessage b {
  font-weight: 500;
}
