@use '@tsp-ui/core/sass/utils';

.header {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}

.assignedToTooltip {
  text-align: center;
}

.avatar {
  width: utils.spacing(4.5);
  height: utils.spacing(4.5);
  font-size: 18px;
  background-color: var(--app-color_primary);
}

.divider {
  margin: utils.spacing(-1, -2, 3, -2);
}

.content {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: utils.spacing(1, 3);
}

.popover {
  padding: utils.spacing(2);
  min-width: 175px;
}

// TODO EXTERNALLY USED STYLES
.card {
  padding: utils.spacing(0.5, 1, 1, 1);
}

.editableCardTable {
  margin-top: utils.spacing(1);
  margin-bottom: utils.spacing(2);

  & > table {
    width: 100%
  }

  table > tbody > tr > td {
    padding-top: utils.spacing(0.5);
    padding-bottom: utils.spacing(0.5);
  }

  input {
    font-size: 14px;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
}
