@use '@tsp-ui/core/sass/utils';

.root {
  width: 350px;
  padding-bottom: utils.spacing(2);
}

.label {
  margin-top: utils.spacing(1);
}

.guidelineContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.button {
  margin-left: utils.spacing(-0.5);
}
