@use '@tsp-ui/core/sass/utils';

.root {
  & tr:hover td {
    background-color: white;
  }

  & td {
    height: 26px; // Height of row with icon. Force here so the rows are consistent height w/o icon.
  }

  & td.iconCell {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.capContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: utils.spacing(1);

  &.leftAlign {
    justify-content: flex-start;
  }
}

.bold {
  font-weight: 500;
}

.noAdjustments {
  margin-top: utils.spacing(-1);
}

.capIcon {
  margin-right: auto;
  transform: rotate(-90deg);
}

.totalsRow > td {
  border-top: 1px solid var(--app-color_border);
}
