@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  gap: utils.spacing(0, 0.5);

  b {
    font-weight: 500;
  }

  .info {
    align-self: center;
    justify-self: center;
  }
}
