@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}

.selected {
  font-weight: 500;
}
