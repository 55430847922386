@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  align-items: flex-start;
  gap: utils.spacing(6);
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: utils.spacing(3);
}

.dateField {
  width: 80px;
}
