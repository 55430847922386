@use '@tsp-ui/core/sass/utils';

.floodZoneInput {
  grid-column: 1/-1
}

.textField {
  width: 260px;
}

.nextButton {
  grid-column: 2;
  justify-self: end;
}
