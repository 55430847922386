@use '@tsp-ui/core/sass/utils';

.activeText {
  margin-top: utils.spacing(1);
  padding-inline: utils.spacing(2);
}

.activeClientsPopover {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(.5);

  h6 {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
  }
}
