@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);

  & > :global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
  }
}
