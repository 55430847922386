@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(2);
  align-items: flex-start;
  justify-content: space-between;
}

.paper {
  padding: utils.spacing(1, 2, 2, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.addFeeButton {
  margin: utils.spacing(-1, -1, -1, 1);
}

.feesSection {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  min-width: 250px;
}

.caption {
  display: block;
  max-width: 300px;
  margin-top: utils.spacing(3);
}
