@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  width: 350px;
}

.row {
  display: flex;
  gap: utils.spacing(2);
  justify-content: space-between;
}

.actionsContainer {
  white-space: nowrap;
  margin: utils.spacing(-1, -1, -1, 0);
}

.additionalInfoMargin {
  margin-top: utils.spacing(1);
}

.expandButton {
  margin: utils.spacing(0, -1, -1, 0);
  align-self: flex-end;
}
