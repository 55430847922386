@use '@tsp-ui/core/sass/utils';

.comments {
  width: 100%;
  margin-top: utils.spacing(2);
}

.suspendMenu {
  padding: utils.spacing(3);
  gap: utils.spacing(1);
  display: flex;
  flex-direction: column;
}
