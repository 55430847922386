@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
  padding: utils.spacing(0.5, 1, 1, 1);

  &.expanded {
    background-color: transparent;
    padding: utils.spacing(0.5);
  }
}

.expandedLabel {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  padding: utils.spacing(0.5, 0, 0.5, 0.5);
  font-weight: 500;
}

.expandButton {
  margin: utils.spacing(-1);
  margin-left: auto;

  &.expanded {
    margin-right: utils.spacing(-0.5);
  }
}

.statusText {
  margin-top: utils.spacing(-0.5);
}
