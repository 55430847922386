@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(2);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: utils.spacing(2);
}

.question {
  display: contents;
}
