@use '@tsp-ui/core/sass/utils';

.root {
    max-width: min-content;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: utils.spacing(2);
}