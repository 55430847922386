@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(0, 2, 2, 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: utils.spacing(1, 4);
  overflow: hidden;
}

.fullWidth {
  grid-column: 1/-1;
}

.ausDetailsHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.ausTypeChip {
  display: inline-flex;
  margin: -1px 0 0 calc(#{utils.spacing(-2)} - 1px);
  border: 1px solid var(--app-color_border);
  padding: utils.spacing(0.5, 2);
  border-bottom-right-radius: var(--app-border-radius);
  align-self: stretch;
  align-items: center;
}

.statusText {
  margin-left: auto;
}

.section {
  display: grid;
  grid-template-columns: auto auto;
  gap: utils.spacing(1, 2);
}

.loanDetailsSection {
  grid-template-columns: auto auto auto;
  align-items: start;
}

.header {
  font-weight: 500;
  grid-column: 1/-1;
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.5, 1);
  margin: utils.spacing(1, -1, 0, -1);
}

.hidden {
  visibility: hidden;
  font-weight: 500;
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(0, -1);
}

.address {
  grid-column: 2/4;
}
