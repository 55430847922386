@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  justify-content: center;
  gap: utils.spacing(3);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
}

.nextCard {
  padding: utils.spacing(2, 2, 1, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.card {
  padding: utils.spacing(0.5, 1, 1, 1);
}

.creditDetailsMainRow {
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.creditDetailsBorrowerName {
  font-weight: 500;
}

.creditDetailsScoreRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2, 3);

  & :global(.MuiTypography-root) {
    text-align: center;
  }

  & input {
    text-align: center;
  }
}

.creditDetailsInput {
  width: 75px;

  & input {
    font-size: 14px;
  }
}
