@use '@tsp-ui/core/sass/utils';

.groupHeader {
  gap: utils.spacing(1);
}

.removeGroupButton {
  margin: utils.spacing(-1, 0, -1, -1);
}

.label {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.progress {
  display: flex;
  margin-right: utils.spacing(1);
}

.dialogPage {
  margin: 0;
  padding: 0;
  width: 75vw;
  height: 75vh;
}
