@use '@tsp-ui/core/sass/utils';

.addressTypography {
  margin-bottom: utils.spacing(1);
}

.paper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.textFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(1, 2);
}

.button {
  align-self: flex-end;
}
