@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: utils.spacing(4);
}

.header {
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.sections {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.uploadLoansContainer {
  min-width: 0; // Prevent it from growing
}

.sectionHeader {
  background-color: var(--app-color_background-medium);
  padding: utils.spacing(1.25);
  border-radius: var(--app-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fileInputContainer {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(2);
}

.pricingResults {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding: utils.spacing(2);
}

.commitmentContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  margin-inline: utils.spacing(2);
}
