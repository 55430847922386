@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(1);
  margin-top: utils.spacing(1);
}

.disableMargin {
  margin-top: 0;
}

.rulesContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}
