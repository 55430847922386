@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(2);
}

.textFields {
  display: grid;
  grid-template-columns: 1fr 90px 90px;
  gap: utils.spacing(2);
}

.coveragePercentage {
  grid-column: 2/4;
}

.button {
  align-self: flex-end;
}
