@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(1);
}

.header {
    font-weight: 500;
    margin-bottom: utils.spacing(1);
}

.flex {
    display: flex;
    flex-wrap: wrap;
    gap: utils.spacing(2, 2);
}

.scroll {
    overflow-x: auto;
}

.documentsTable table {
    width: 100%;
}

.fullWidth {
    margin-top: utils.spacing(1);
    white-space: pre-wrap;
}
