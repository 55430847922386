@use '@tsp-ui/core/sass/utils';

.highLevelLabel {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
    line-height: 1;
}

.addButton {
    margin-left: auto;
    margin-bottom: utils.spacing(-1);
}

.highLevelContainer {
    display: flex;
    gap: utils.spacing(2);
    align-items: flex-start;
    margin-top: utils.spacing(0.5);
}