@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.conditions {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.header {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  align-items: center;
  margin-bottom: utils.spacing(-1);
}

.noConditions {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  align-self: center;
  align-items: center;
  padding: utils.spacing(2);

  & > button {
    margin-top: utils.spacing(1);
  }
}

.mainRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
  padding: utils.spacing(0.5);

  .dateText {
    grid-column: 1/-1;
    margin-right: utils.spacing(1);
  }
}

.description {
  flex: 1 1 auto;
}

.info {
  flex: 0 0 155px;
  display: flex;
  flex-direction: column;
}

.needsReview {
  border-color: var(--app-color_warn);
  color: var(--app-color_warn);
}

.exceptionButton {
  margin-top: utils.spacing(0.5);
  margin-left: -5px; // Matches small button's padding
}

.editButton {
  margin-left: utils.spacing(1);
}

.statusContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: utils.spacing(1);
  margin-top: utils.spacing(-1);
}

.fileInput {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  margin: 0 !important;
  gap: utils.spacing(3) !important;
}

.expandedContent {
  display: grid;
  align-items: start;
  justify-content: space-between;
  grid-template-columns: auto minmax(auto, 300px);
  gap: utils.spacing(3);
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
}

.filledCard {
  background-color: var(--app-color_background);
  padding: utils.spacing(0.5);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}

.card {
  padding: utils.spacing(0.5);
}

.timelineSeparator {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: utils.spacing(-0.5, 0);

  &:before, &:after {
    content: '';
    width: 1px;
    height: utils.spacing(0.75);
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:before {
    margin-bottom: utils.spacing(-0.25);
  }

  &:after {
    margin-top: utils.spacing(-0.25);
  }
}

.buttons {
  display: flex;
  grid-column: 1/-1;
  justify-content: flex-end;
  gap: utils.spacing(1);
  margin-bottom: utils.spacing(1);
}

.clearButton {
  margin-left: auto;
}

.addNoteCard {
  padding: utils.spacing(0.5, 1, 1, 1);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}

.addNoteButtons {
  display: flex;
  justify-content: flex-end;
}

.actionCardIcon {
  float: left;
  margin-right: utils.spacing(1);
}

.actionCardDate {
  float: right;
  margin-left: utils.spacing(2);
}

.conditionDetail {
  margin-top: utils.spacing(0.5);
}
