@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';

.unsetMaxWidth {
  max-width: unset !important;
}

.dialogActions {
  align-self: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: utils.spacing(2);
  grid-column: 1/-1;
  margin-top: utils.spacing(3);
}

.stepperContent {
  align-self: center;
  display: flex;
  flex: 0 0 auto;
  padding-bottom: 0;
}

.stepper {
  width: 350px;
}

.pageStepper {
  margin-bottom: utils.spacing(4);
}

.matrixStepper {
  position: absolute;
  top: utils.spacing(2);
  left: 50%;
  transform: translate(-50%);
}

.content {
  display: flex;
  flex-direction: column;
}

.matrixContent {
  padding-block: utils.spacing(0, 1);
  overflow: visible;
  margin-top: utils.spacing(-1);
}

.matrixFormRoot {
  flex: 1;
}

.fullWidth {
  width: 100%;
}

.detailsFields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: utils.spacing(2);
}

.baseEligibilityButtons {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.createDetails {
  width: 550px;
  padding: utils.spacing(3);
}

.gridContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

.innerGridContainer {
  height: 100%;
  display: flex;
}

.exclusionPaper {
  width: auto;
}

.filterContainer {
  padding: utils.spacing(1.5, 2);
  background-color: var(--app-color_background);
}

.loanProgramChip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  white-space: nowrap;
  padding: utils.spacing(0.25, 0.5);
  color: var(--app-color_text-secondary);
  text-decoration: none;

  &:hover {
      cursor: pointer;
      box-shadow: var(--shadow-1);
  }

  &.selected {
    background: rgba(255, 255, 255, .2)
  }
}

.select {
  padding-top: utils.spacing(1);
}

.inheritOrCreateButton {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);
  height: 100%;
}

.icon {
  font-size: utils.spacing(5);
}

.divider {
  margin-inline: utils.spacing(-1.5);
}
