@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
  min-width: 300px;
  max-width: 400px;
}

.fullWidth {
  grid-column: 1/-1;
}

.bottomRow {
  display: flex;
  justify-content: flex-end;

  :nth-child(2) {
    margin-left: auto;
  }
}

.addresses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

