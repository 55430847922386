@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';

.slideContainer {
  position: absolute;
  width: 100%;
}

.navList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
