@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: utils.spacing(1);
}

.rightContainer {
  display: flex;
  align-items: center;
}

.code {
  margin-left: utils.spacing(1);
}

.lineThrough {
  text-decoration: line-through;
}

.icon {
  margin: utils.spacing(0, .5);
}
